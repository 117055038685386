import React, { useEffect, useState } from "react";
import './WomenDayPage.scss';
import WomenDayPageDisplay from './WomenDayPage.styled';
import ReactPlayer from "react-player";
import axios from "axios";
import { useHistory } from "react-router";
import Modal from 'react-awesome-modal';
import { client } from "../../auth/Client";

import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function WomenDayPage({ t, props }) {

	const [text, setText] = useState("");
	const [img, setImg] = useState([]);
	const [errors, seterrors] = useState(false);
	const [errorMsg, seterrorMsg] = useState("");
	const [fileSize, setfileSize] = useState("");
	const [fileName, setfileName] = useState("");
	const [visablemodalwomen, setvisablemodalwomen] = useState(false);



	var currentLang = localStorage.getItem("i18nextLng");
	const history = useHistory();

	useEffect(() => {
		checkAuth()

	})

	async function checkAuth() {
		console.log('checkAuth')
		const authorized = await client.auth();
		if (!authorized) {
			history.push('/login')
		}

	}

	function handleChange(value, key) {
		console.log(value);
		if (key === 'text') {
			setText(value);
		}
		else {
			setImg(value.target.files[0]);
			document.getElementById('fileupload').style.display='block'

			if (value.target.files.length > 0) {
				setfileName(value.target.files[0].name);
				setfileSize(Math.round((value.target.files[0].size) / 1024));
				//this.showfile = true;
			  }
			  

		}
	}
	function openfile(e) {
		e.preventDefault();
		document.getElementById('file').click();
	}

	async function sendComment(e) {
		e.preventDefault();
		console.log("send");
		const formData = new FormData();
		formData.append("photo", img);
		formData.append("msisdn", localStorage.getItem('phoneNum'));
		formData.append("userId", localStorage.getItem('userId'));
		formData.append("text", text);
		formData.append("event", "women");



		try {
			var apiUrl = "http://api.playit.mobi/api/v2/playit/pastime";
			const res = await axios.post(apiUrl, formData);
			//console.log("res", res.status)
			console.log("res", res)

			if (res.data.status == true) {
				setvisablemodalwomen(true)
				setTimeout(() => {
					history.push("/");
				}, 3000);

			}

			else {
				seterrors(true);
				seterrorMsg(res.data.message)

			}


		} catch (err) {
			console.log(err)

		}
	}

	return (
		<WomenDayPageDisplay>
			<div className="operatorSignup" style={{ backgroundColor: "#12151D" }}>
				<div className="formsLayout">
					{/* <div className="header">
								<div className="logo" ></div>
						</div> */}
					<div className="links-Section-women">
						<div className="headetwomen">
							<div className="headerwomenLogo" style={{ backgroundImage: currentLang == 'en' ? "url('/assets/images/Group 2770.svg')" : "url('/assets/images/Group 2769.svg')" }}>
							</div>

							<div style={{ width: "100%" }}>
								<p><Trans>uploadimg</Trans></p>

								<button
									type="button"
									className="uploadimg"
									onClick={e => { openfile(e) }}

								>

								</button>

								<input
									style={{ display: 'none' }}
									className="inputs"
									type="file"
									id="file"
									onChange={e => handleChange(e, 'file')}
								/>
								{/* <p className="fileupload" id="fileupload">{fileName}</p> */}
								<p className="fileupload" id="fileupload"><Trans>img_upload</Trans></p>


								
							</div>
							<p><Trans>commentPlacholder</Trans></p>
						</div>

						<div className="comments-women">
							<form>
								<div className="inputs input-women">
									<textarea
										className="inputs"
										onChange={e => handleChange(e.target.value, 'text')}
									>
									</textarea>


								</div>
							</form>
						</div>
						<div style={{ marginTop: '-5vh' }} className="wommen">

							<button
								type="button"
								className="womensend"
								onClick={e => { sendComment(e) }}
								disabled={text ? '' : 'disabled'}
							>
								<Trans>Send</Trans>
							</button>

							{/* <input
								style={{ display: '' }}
								className="inputs"
								type="file"
								id="file"
								onChange={e => handleChange(e, 'file')}
							/> */}

						</div>



						<div className="footer">
							<div className="ncellTos ncellTos-women">
								{/* <h3><Trans>valntine-terms</Trans></h3> */}

								{/* <input type="checkbox" className="inputcheck"/> */}

								<a href="http://api.playit.mobi//api/v2/playit/pdf">
								<p><Trans>terms_and_conditions</Trans> <span style={{color:'#ff4172'}}> <Trans>click</Trans></span></p>
								</a>

							</div>
						</div>

					</div>



				</div>
			</div>


			<Modal
				visible={visablemodalwomen}
				width="350"
				height="45"
				effect="fadeInUp"
				padding="0"
			//onClickAway={() => closeModalSubscribe()}
			>
				<div className="womenmodal">

					<p><Trans>commentSend</Trans></p>

				</div>


			</Modal>

			{errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}

		</WomenDayPageDisplay>
	)
}

export default withTranslation()(WomenDayPage)
